<template>
	<div class="mx-5">
		<div class="container flex flex-col m-auto">
			<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
					<TFileReader
						:upload="keywordsUpload"
						:count="keywordsUploadCounter"
						:error="keywordsUploadError"
						@load="upload"
					/>
					<h3 class="mt-10 mb-5 text-lg font-medium text-gray-900 leading-6">
						{{ $i18n("Keywords") }}
					</h3>
					<DataFilter
						:default="dataFilter.default"
						:defaultOptions="dataFilter.options"
					>
						<template #create>
							<router-link
								:to="{ path: `/keyword/create` }"
								class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-l-0 border-transparent rounded rounded-l-none shadow-sm rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
							>
								{{ $i18n("Create") }}
							</router-link>
						</template>
					</DataFilter>
					<Loader v-if="keywordsLoad" />
					<div class="flex">
						<t-pagination
							v-if="!keywordsLoad && keywords.data.length"
							v-model="currentPage"
							:per-page="keywordsLimit"
							:total-items="keywords.meta.results_count"
							variant="rounded"
							class="mx-auto mb-5"
						/>
					</div>
					<div
						v-if="!keywordsLoad"
						class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
					>
						<table class="min-w-full divide-y divide-gray-200">
							<thead class="bg-gray-50">
								<tr>
									<th
										scope="col"
										class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
									>
										{{ $i18n("Keyword") }}
									</th>
									<th scope="col" class="relative px-6 py-3"></th>
								</tr>
							</thead>
							<tbody class="bg-white divide-y divide-gray-200">
								<tr
									v-for="({ id, value }, index) in keywords.data"
									:key="`keywords${index}`"
								>
									<td class="py-4 pr-6">
										<div class="flex items-center">
											<div class="ml-4">
												<div class="text-sm text-gray-500 break-all">
													{{ value }}
												</div>
											</div>
										</div>
									</td>
									<td class="py-4 pr-6 text-sm font-medium text-right whitespace-nowrap">
										<div
											@click="deleteKeywordByIdMethod({ id, value })"
											class="text-red-600 cursor-pointer hover:text-red-900"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												class="inline w-6 h-6"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
												/>
											</svg>
										</div>
									</td>
								</tr>

								<!-- More feed... -->
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<t-pagination
				v-if="!keywordsLoad && keywords.data.length"
				v-model="currentPage"
				:per-page="keywordsLimit"
				:total-items="keywords.meta.results_count"
				variant="rounded"
				class="mt-5"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";
import DataFilter from "@/components/DataFilter";
import FileReader from "@/components/FileReader";

export default {
	name: "Keywords",
	components: {
		Loader,
		DataFilter,
		TFileReader: FileReader,
	},
	props: {
		page: {
			type: String,
			default: "1",
		},
	},
	data() {
		return {
			dataFilter: {
				default: {
					order_by: "id",
					order_type: "ASC",
					search: undefined,
				},
				options: {
					order_by: ["id", "value"],
					order_type: ["ASC", "DESC"],
				},
			},
		};
	},
	computed: {
		...mapGetters("keywords", [
			"keywords",
			"keywordsLimit",
			"keywordsLoad",
			"keywordsUploadError",
			"keywordsUploadCounter",
			"keywordsUpload",
		]),
		currentPage: {
			get() {
				return Number(this.page);
			},
			async set(page) {
				try {
					const { $route, $router } = this;
					const {
						$route: { params },
					} = this;
					const route = {
						...$route,
						params: { ...params, page: String(page) },
					};
					await $router.push(route);
					await this.fetch();
				} catch (error) {
					console.debug(error);
				}
			},
		},
	},
	methods: {
		...mapActions("keywords", [
			"fetchKeywords",
			"createKeyword",
			"uploadKeywords",
			"deleteKeywordById",
		]),
		async deleteKeywordByIdMethod({ id, value }) {
			try {
				const {
					$router,
					$dialog: { confirm },
					$i18n,
					currentPage,
					fetch,
				} = this;

				const { isOk } = await confirm({
					clickToClose: false,
					escToClose: false,
					title: $i18n("Delete") + " " + value + " ?",
					icon: "info",
					cancelButtonText: $i18n("Cancel"),
					okButtonText: $i18n("Do"),
				});
				if (isOk) {
					await this.deleteKeywordById({ id, value });

					const resultsCount = this.keywords.data.length;

					if (currentPage !== 1 && !resultsCount) {
						await $router.replace({
							...this.$route,
							params: { ...this.$route.params, page: this.$route.params.page - 1 },
						});
					}
					await fetch();
				}
			} catch (error) {
				console.debug(error);
			}
		},
		async fetch({ withPage = true } = {}) {
			try {
				const {
					page,
					$route: { query },
				} = this;

				await this.fetchKeywords({
					page: withPage ? page : undefined,
					query,
				});

				return this.keywords.data.length;
			} catch (error) {
				console.debug(error);
			}
		},
		formatDate({ date }) {
			return new Date(date).toLocaleDateString();
		},
		async upload(keywords) {
			try {
				await this.uploadKeywords(keywords);
				await this.fetch();
			} catch (error) {
				console.debug(error);
			}
		},
	},
	async created() {
		try {
			this.$on("Filter", async () => {
				await this.fetch({ withPage: false });
			});
			await this.fetch();
		} catch (error) {
			console.debug(error);
		}
	},
};
</script>
